import React from 'react';
import Layout from '../src/components/Layout';

export function wrapPageElement({ element, props }) {
	return <Layout {...props}>{element}</Layout>;
}

export function onClientEntry() {
	if (process.env.NODE_ENV !== 'production') {
	  const whyDidYouRender = require('@welldone-software/why-did-you-render')
	  whyDidYouRender(React, {
		trackAllPureComponents: true
	  })
	}
  }