import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import 'normalize.css';
import '../styles/font-face.css';

import { GlobalStyles } from '../styles';
import { useWindowSize } from '../utils';

const BgCenter = styled.div`
	min-height: 100%;
	font-family: CallunaSemibold;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
`;

export default function Layout({ children }) {
	const windowSize = useWindowSize();
	const data = useStaticQuery(graphql`
		query HeaderQuery {
			site {
				siteMetadata {
					title
				}
			}
		}
	`);
	return (
		<>
			<GlobalStyles />
			<Helmet>
                <meta charSet="utf-8" />
                <title>{data.site.siteMetadata.title}</title>
				<link rel="icon" href="favicon.svg" />
				<link rel="mask-icon" href="favicon.svg" color="#7EBD4C" />
            </Helmet>
			<BgCenter style={{ height: `${windowSize.height}px` }}>
				{children}
			</BgCenter>
		</>
	);
}
