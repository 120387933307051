import { createGlobalStyle } from 'styled-components';

import callunaSemibold from '../assets/fonts/CallunaSemibold.woff2';
import merriweatherLightItalic from '../assets/fonts/MerriweatherLightItalic.woff2';
import nunitoSansExtraBold from '../assets/fonts/NunitoSansExtraBold.woff2';

/*
Declare font-face outside styled-components!
https://github.com/styled-components/styled-components/issues/1593
*/

export const TypographyStyles = createGlobalStyle`
	/* @font-face {
		font-family: CallunaSemibold;
		src: url(${callunaSemibold});
		font-weight: normal;
	}
	@font-face {
		font-family: MerriweatherLightItalic;
		src: url(${merriweatherLightItalic});
		font-weight: normal;
	}
	@font-face {
		font-family: NunitoSansExtraBold;
		src: url(${nunitoSansExtraBold});
		font-weight: normal;
	} */
`;

export default TypographyStyles;
