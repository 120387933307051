import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
	:root {
		--clrBrand: #7EBD4C;
		--clrBody: #3C3C3C;
		--clrDimmed: #8D8D8D;
	}
	html {
		font-size: 10px;
		color: var(--clrBody);
		background: radial-gradient(186.43% 186.43% at 50% 50%, rgba(134, 97, 2, 0) 14.58%, rgba(149, 107, 0, 0.6) 100%);
	}
	body {
		font-size: 1.8rem;
	}
	h1, h2, h3 {
		font-weight: normal;
		margin: 0;
	}
`;
